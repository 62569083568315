<template>
   
   <!-- body -->
   <div class="main-layout">
      <!-- loader  -->
      <div class="loader_bg">
         <div class="loader"><img src="/landing-page/images/loading.gif" alt="#"/></div>
      </div>
      <!-- end loader -->
      <!-- header -->
      <header>
         <!-- header inner -->
         <div class="header">
            <div class="container">
               <div class="row">
                  <div class="col-md-12 col-sm-3 col logo_section">
                     <div class="full">
                        <div class="center-desk">
                           <div class="logo">
                              <a href="#"><img src="/landing-page/images/logo.png" width="250" alt="#" /></a>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-11 offset-md-1">
                     <nav class="navigation navbar navbar-expand-md navbar-dark">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                           <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarsExample04">
                           <ul class="navbar-nav mr-auto">
                              <li class="nav-item active">
                                 <a class="nav-link" href="#">{{ $t('landingPage.navbar.home') }}</a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link" href="#about">{{ $t('landingPage.navbar.about') }}</a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link" href="#service">{{ $t('landingPage.navbar.services') }}</a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link" href="#gallery">{{ $t('landingPage.navbar.gallery') }}</a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link" href="#testimonial">{{ $t('landingPage.navbar.testimonial') }}</a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link" href="#contact">{{ $t('landingPage.navbar.contactUs') }}</a>
                              </li>
                           </ul>
                        </div>
                        <!-- Dropdown para cambiar idioma -->
                        <div class="language-dropdown ml-auto">
                           <button 
                              class="btn btn-primary dropdown-toggle" 
                              type="button" 
                              id="languageDropdown" 
                              data-toggle="dropdown" 
                              aria-haspopup="true" 
                              aria-expanded="false">
                              {{ $t('landingPage.navbar.language') }}
                           </button>
                           <div class="dropdown-menu" aria-labelledby="languageDropdown">
                              <a class="dropdown-item" href="#" @click.prevent="setLanguage('es')">Español</a>
                              <a class="dropdown-item" href="#" @click.prevent="setLanguage('en')">English</a>
                           </div>
                        </div>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </header>
      <!-- end header inner -->
      <!-- end header -->
      <!-- banner -->
      <section class="banner_main">
         <div id="myCarousel" class="carousel slide banner" data-ride="carousel">
            <ol class="carousel-indicators">
               <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
               <li data-target="#myCarousel" data-slide-to="1"></li>
               <li data-target="#myCarousel" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
               <div class="carousel-item active">
                  <div class="container">
                     <div class="carousel-caption relative">
                        <div class="row">
                           <div class="col-md-7 offset-md-5">
                              <div class="text-bg">
                                 <h1> {{ $t('landingPage.central.carousel1.title') }} <br> {{ $t('landingPage.central.carousel1.title2') }} </h1>
                                 <span>{{ $t('landingPage.central.carousel1.message') }}</span>
                                 <a class="read_more" href="#gallery">{{ $t('landingPage.general.readMore') }}</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="carousel-item">
                  <div class="container">
                     <div class="carousel-caption relative">
                        <div class="row">
                           <div class="col-md-7 offset-md-5">
                              <div class="text-bg">
                                 <h1> {{ $t('landingPage.central.carousel2.title') }} <br> {{ $t('landingPage.central.carousel2.title2') }} </h1>
                                 <span>{{ $t('landingPage.central.carousel2.message') }}</span>
                                 <a class="read_more" href="#service">{{ $t('landingPage.general.readMore') }}</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="carousel-item">
                  <div class="container">
                     <div class="carousel-caption relative">
                        <div class="row">
                           <div class="col-md-7 offset-md-5">
                              <div class="text-bg">
                                 <h1> {{ $t('landingPage.central.carousel3.title') }} <br> {{ $t('landingPage.central.carousel3.title2') }} </h1>
                                 <span>{{ $t('landingPage.central.carousel3.message') }}</span>
                                 <a class="read_more" href="#testimonial">{{ $t('landingPage.general.readMore') }}</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
            <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
            <i class="fa fa-angle-right" aria-hidden="true"></i>
            <span class="sr-only">Next</span>
            </a>
         </div>
      </section>
      <!-- end banner -->
      <!-- about -->
      <div id="about" class="about">
         <div class="container">
            <div class="row">
               <div class="col-md-5">
                  <div class="titlepage">
                     <h2> {{ $t('landingPage.aboutUs.title') }} <span class="green"> {{ $t('landingPage.aboutUs.title2') }} </span></h2>
                     <p> {{ $t('landingPage.aboutUs.message') }} </p>
                  </div>
               </div>
               <div class="col-md-7">
                  <div class="about_img">
                     <figure><img src="/landing-page/images/presentation_card.png" alt="#"/></figure>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- end about -->
      <!--  service -->
      <div id="service" class="service">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="titlepage">
                     <h2> {{ $t('landingPage.ourServices.title') }} <span class="green"> {{ $t('landingPage.ourServices.title2') }} </span></h2>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-10 offset-md-1">
                  <div class="row">
                     <div class="col-md-4 col-sm-6">
                        <div class="service_box">
                           <i><img src="/landing-page/images/service1.png" alt="#"/></i>
                           <h3> {{ $t('landingPage.ourServices.service1.title') }} </h3>
                           <p> {{ $t('landingPage.ourServices.service1.message') }} </p>
                        </div>
                     </div>
                     <div class="col-md-4 offset-md-1 col-sm-6">
                        <div class="service_box">
                           <i><img src="/landing-page/images/service2.png" alt="#"/></i>
                           <h3> {{ $t('landingPage.ourServices.service2.title') }} </h3>
                           <p> {{ $t('landingPage.ourServices.service2.message') }} </p>
                        </div>
                     </div>
                     <div class="col-md-4 offset-md-3 col-sm-6 mar_top">
                        <div class="service_box">
                           <i><img src="/landing-page/images/service3.png" alt="#"/></i>
                           <h3> {{ $t('landingPage.ourServices.service3.title') }} </h3>
                           <p> {{ $t('landingPage.ourServices.service3.message') }} </p>
                        </div>
                     </div>
                     <div class="col-md-4 offset-md-1 col-sm-6 mar_top">
                        <div class="service_box">
                           <i><img src="/landing-page/images/service4.png" alt="#"/></i>
                           <h3> {{ $t('landingPage.ourServices.service4.title') }} </h3>
                           <p> {{ $t('landingPage.ourServices.service4.message') }} </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- end service -->
      <!-- gallery -->
      <div id="gallery"  class="gallery">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="titlepage">
                     <h2> {{ $t('landingPage.ourGallery.title') }} <span class="green"> {{ $t('landingPage.ourGallery.title2') }} </span></h2>
                     <p> {{ $t('landingPage.ourGallery.message') }} </p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_text">
                     <div class="galleryh3">
                        <h3> {{ $t('landingPage.ourGallery.gallery1.title') }} </h3>
                        <p> <br>
                           {{ $t('landingPage.ourGallery.gallery1.description1') }} <br>
                           {{ $t('landingPage.ourGallery.gallery1.description2') }} <br>
                           {{ $t('landingPage.ourGallery.gallery1.description3') }} <br>
                           {{ $t('landingPage.ourGallery.gallery1.description4') }}
                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_img">
                     <figure><img src="/landing-page/images/gallery/gallery1.jpg" alt="#"/></figure>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_img">
                     <figure><img src="/landing-page/images/gallery/gallery2.jpg" alt="#"/></figure>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_img">
                     <figure><img src="/landing-page/images/gallery/gallery3.jpg" alt="#"/></figure>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_img">
                     <figure><img src="/landing-page/images/gallery/gallery4.jpg" alt="#"/></figure>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_text">
                     <div class="galleryh3">
                        <h3> {{ $t('landingPage.ourGallery.gallery2.title') }} </h3>
                        <p> <br>
                           {{ $t('landingPage.ourGallery.gallery2.description1') }} <br>
                           {{ $t('landingPage.ourGallery.gallery2.description2') }} <br>
                           {{ $t('landingPage.ourGallery.gallery2.description3') }} <br>
                           {{ $t('landingPage.ourGallery.gallery2.description4') }}
                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_text">
                     <div class="galleryh3">
                        <h3> {{ $t('landingPage.ourGallery.gallery3.title') }} </h3>
                        <p> <br>
                           {{ $t('landingPage.ourGallery.gallery3.description1') }} <br>
                           {{ $t('landingPage.ourGallery.gallery3.description2') }} <br>
                           {{ $t('landingPage.ourGallery.gallery3.description3') }} <br>
                           {{ $t('landingPage.ourGallery.gallery3.description4') }}
                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_img">
                     <figure><img src="/landing-page/images/gallery/gallery5.jpg" alt="#"/></figure>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_img">
                     <figure><img src="/landing-page/images/gallery/gallery6.jpg" alt="#"/></figure>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_img">
                     <figure><img src="/landing-page/images/gallery/gallery7.jpg" alt="#"/></figure>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_img">
                     <figure><img src="/landing-page/images/gallery/gallery8.jpg" alt="#"/></figure>
                  </div>
               </div>
               <div class="col-md-4 col-sm-6">
                  <div class="gallery_text">
                     <div class="galleryh3">
                        <h3> {{ $t('landingPage.ourGallery.gallery4.title') }} </h3>
                        <p> <br>
                           {{ $t('landingPage.ourGallery.gallery4.description1') }} <br>
                           {{ $t('landingPage.ourGallery.gallery4.description2') }} <br>
                           {{ $t('landingPage.ourGallery.gallery4.description3') }}
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- end gallery -->
      <!-- testimonial -->
      <div id="testimonial" class="Testimonial">
         <div class="container-fluid">
            <div class="row d_flex">
               <div class="col-md-8 pad_left0">
                  <div id="testimon" class="carousel slide banner_testimonial" data-ride="carousel">
                     <ol class="carousel-indicators">
                        <li data-target="#testimon" data-slide-to="0" class="active"></li>
                        <li data-target="#testimon" data-slide-to="1"></li>
                        <li data-target="#testimon" data-slide-to="2"></li>
                     </ol>
                     <div class="carousel-inner">
                        <div v-for="(review, index) in reviews" :key="index" class="carousel-item" :class="{'active': index === 0}">
                           <div class="container">
                              <div class="carousel-caption relative">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class="text_humai">
                                          <i><img src="/landing-page/images/tett1.png" alt="#"/></i>
                                          <span>{{ review.name }}</span>
                                          <p>{{ review.review }}</p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <a class="carousel-control-prev" href="#testimon" role="button" data-slide="prev">
                     <i class="fa fa-angle-left" aria-hidden="true"></i>
                     <span class="sr-only">Previous</span>
                     </a>
                     <a class="carousel-control-next" href="#testimon" role="button" data-slide="next">
                     <i class="fa fa-angle-right" aria-hidden="true"></i>
                     <span class="sr-only">Next</span>
                     </a>
                  </div>
               </div>
               <div class="col-md-4 ">
                  <div class="titlepage">
                     <h2> {{ $t('landingPage.testimonial.title') }} </h2>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- end design -->
       <!--  review -->
      <div id="review" class="review">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="titlepage">
                     <h2> {{ $t('landingPage.review.title') }} </h2>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-6">
                  <form @submit.prevent="sendReview" class="main_form_review">
                     <div class="row">
                        <div class="col-md-12">
                           <input v-model="formReview.name" class="reviewus" :placeholder="$t('landingPage.review.form.name')" name="Name">
                           <span v-if="nameReviewRequired" style="color: red;"> {{ $t('landingPage.general.requiredField') }} </span> 
                        </div>
                        <div class="col-md-12">
                           <textarea v-model="formReview.message" maxlength="255" class="textarea" :placeholder="$t('landingPage.review.form.message')" name="Message"></textarea>
                           <span v-if="messageReviewRequired" style="color: red;"> {{ $t('landingPage.general.requiredField') }} </span> 
                        </div>
                        <div class="col-md-12">
                           <button type="submit" class="send_btn"> {{ $t('landingPage.review.form.send') }} </button>
                        </div>
                     </div>
                  </form>
               </div>
               <div class="col-md-6">
                  <div class="about_img">
                     <figure><img src="/landing-page/images/hear_from_you.png" alt="#"/></figure>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- end review -->
      <!--  contact -->
      <div id="contact" class="contact">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="titlepage">
                     <h2> {{ $t('landingPage.contactUs.title') }} </h2>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-6">
                  <form @submit.prevent="sendEmailContactUs" class="main_form">
                     <div class="row">
                        <div class="col-md-12">
                           <input v-model="formEmail.name" class="contactus" :placeholder="$t('landingPage.contactUs.form.name')" name="Name">
                           <span v-if="nameRequired" style="color: red;"> {{ $t('landingPage.general.requiredField') }} </span> 
                        </div>
                        <div class="col-md-12">
                           <input v-model="formEmail.email" class="contactus" :placeholder="$t('landingPage.contactUs.form.email')" :pattern="emailPattern" name="Email">
                           <span v-if="emailNotValid" style="color: red;"> {{ $t('landingPage.general.emailNotValid') }} </span> <br>
                           <span v-if="emailRequired" style="color: red;"> {{ $t('landingPage.general.requiredField') }} </span> 
                        </div>
                        <div class="col-md-12">
                           <input v-model="formEmail.phoneNumber" class="contactus" :placeholder="$t('landingPage.contactUs.form.phone')" name="Phone Number" @keypress="number($event)" maxlength="12">  
                           <span v-if="phoneRequired" style="color: red;"> {{ $t('landingPage.general.requiredField') }} </span>                         
                        </div>
                        <div class="col-md-12">
                           <textarea v-model="formEmail.message" maxlength="255" class="textarea" :placeholder="$t('landingPage.contactUs.form.message')" name="Message"></textarea>
                        </div>
                        <div class="col-md-12">
                           <button type="submit" class="send_btn"> {{ $t('landingPage.contactUs.form.send') }} </button>
                        </div>
                     </div>
                  </form>
               </div>
               <div class="col-md-6">
                  <div class="map_main">
                     <div class="map-responsive">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d232.76455093708788!2d-89.54616293015292!3d21.023368526569378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2smx!4v1730473032174!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- end contact -->
      <!--  footer -->
      <footer>
         <div class="footer">
            <div class="container">
               <div class="row">
                  <div class=" col-md-3 col-sm-6">
                     <h3> {{ $t('landingPage.footer.followUs') }} </h3>
                     <ul class="social_icon">
                        <li><a href="https://www.facebook.com/profile.php?id=61563259792152"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a href="https://www.instagram.com/worldroverrental/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li><a href="https://wa.me/529991183453"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                     </ul>
                     <p class="variat pad_roght2">
                        {{ $t('landingPage.footer.description') }}
                     </p>
                  </div>
               </div>
            </div>
            <div class="copyright">
               <div class="container">
                  <div class="row">
                     <div class="col-md-10 offset-md-1">
                        <p>© 2024 All Rights Reserved.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
      <!-- end footer -->
    </div>
</template>

<style>
.language-dropdown {
   position: relative;
   z-index: 1000;
}

.language-dropdown .btn-primary {
   background-color: #1D4E9C;
   border-color: #1D4E9C;
   min-width: 100px;
}

.language-dropdown .btn-primary:hover {
   background-color: #F7AC1F;
   border-color: #F7AC1F;
}

.language-dropdown .dropdown-menu {
   left: auto;
   right: 0;
   margin-top: 5px;
}

.language-dropdown .dropdown-item:hover {
   background-color: #1D4E9C;
   color: #ffffff;
}
.swal2-confirm.custom-confirm-button {
  background-color: #1D4E9C;
  color: white;
  font-weight: bold;
}

.swal2-confirm.custom-confirm-button:hover {
  background-color: #1D4E9C;
}


</style>
<script>
import { useHead } from '@vueuse/head'
import axios from '../../axios';
import swal from 'sweetalert2'; 

export default {
    name: 'LandingPage',
    setup() {
        useHead({
            title: 'WorldRover Rental',
            meta: [
                { name: 'charset', content: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'viewport', content: 'initial-scale=1, maximum-scale=1' },
                { name: 'keywords', content: '' },
                { name: 'description', content: '' },
                { name: 'author', content: '' }
            ],
            link: [
                { rel: 'stylesheet', href: '/landing-page/css/bootstrap.min.css' },
                { rel: 'stylesheet', href: '/landing-page/css/style.css' },
                { rel: 'stylesheet', href: '/landing-page/css/responsive.css' },
                { rel: 'icon', href: '/landing-page/images/favicon.png', type: 'image/gif' },
                { rel: 'stylesheet', href: '/landing-page/css/jquery.mCustomScrollbar.min.css' },
                { rel: 'stylesheet', href: 'https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css' },
                { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.5/jquery.fancybox.min.css', media: 'screen' }
            ],
        })
    },
    data: () => ({
        message: '',
        formEmail: {
         name: '',
         email: '',
         phoneNumber: null,
         message: ''
        },
        formReview: {
         name: '',
         message: ''
        },
        emailPattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        nameRequired: false,
        emailRequired: false,
        emailNotValid: false,
        phoneRequired: false,
        nameReviewRequired: false,
        messageReviewRequired: false,
        reviews: []
    }),

    mounted() {
        this.loadScript('/landing-page/js/jquery.min.js')
      .then(() => this.loadScript('/landing-page/js/bootstrap.bundle.min.js'))
      .then(() => this.loadScript('/landing-page/js/jquery-3.0.0.min.js'))
      .then(() => this.loadScript('/landing-page/js/jquery.mCustomScrollbar.concat.min.js'))
      .then(() => this.loadScript('/landing-page/js/custom.js'))
      .catch(err => console.error(err));
      /*axios.get('/example')
        .then(response => {
            this.message = response.data.message;
            console.log(this.message)
        })
        .catch(error => {
            console.error(error);
        });*/
      this.getReviews();
    },
    methods: {
      loadScript(src) {
         return new Promise((resolve, reject) => {
         const script = document.createElement('script');
         script.src = src;
         script.onload = resolve;
         script.onerror = reject;
         document.head.appendChild(script);
         });
      },

      setLanguage(language) {
         this.$i18n.locale = language;
      },

      async getReviews(){
         try {
            this.loadingSwal()
            const response = await axios.get('/get-reviews');
            if (response.data) {
               this.reviews = response.data;
            } else {
               this.failedSwal(this.$t('landingPage.review.getErrorTitle'),this.$t('landingPage.review.getErrorMessage'))
            }
         } catch (error) {
            this.failedSwal(this.$t('landingPage.review.getErrorTitle'),this.$t('landingPage.review.getErrorMessage'))
         }finally {
            swal.close();
         }
      },

      async sendEmailContactUs(){
         this.nameRequired = this.formEmail.name === '';
         this.emailRequired = this.formEmail.email === '';
         this.emailNotValid = !this.emailPattern.test(this.formEmail.email);
         this.phoneRequired = this.formEmail.phoneNumber === null;

         if(this.nameRequired || this.emailRequired || this.emailNotValid || this.phoneRequired) {
            return true;
         }

         try {
            this.loadingSwal()
            const response = await axios.post('/send-email', this.formEmail);
            this.successSwal(this.$t('landingPage.sendEmailSuccessError.successTitle'),this.$t('landingPage.sendEmailSuccessError.successMessage'))
            if (response.data.success) {
               this.formEmail = {
                  name: '',
                  email: '',
                  phoneNumber: null,
                  message: ''
               }
            }
         } catch (error) {
            console.log(error)
         }
      },

      async sendReview(){
         this.nameReviewRequired = this.formReview.name === '';
         this.messageReviewRequired = this.formReview.message === '';

         if(this.nameReviewRequired || this.messageReviewRequired) {
            return true;
         }

         try {
            this.loadingSwal()
            const response = await axios.post('/send-review', this.formReview);
            if (response.data.status == 'success') {
               this.successSwal(this.$t('landingPage.sendReviewSuccessError.successTitle'),this.$t('landingPage.sendReviewSuccessError.successMessage'))
               this.formReview = {
                  name: '',
                  message: ''
               }
            } else {
               this.failedSwal(this.$t('landingPage.sendReviewSuccessError.errorTitle'),this.$t('landingPage.sendReviewSuccessError.errorMessage'))
            }
         } catch (error) {
            this.failedSwal(this.$t('landingPage.sendReviewSuccessError.errorTitle'),this.$t('landingPage.sendReviewSuccessError.errorMessage'))
         }
      },

      number: function(evt){
         evt = (evt) ? evt : window.event;
         var charCode = (evt.which) ? evt.which : evt.keyCode;
         if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
         } else {
            return true;
         }
      },

      loadingSwal (){
         swal.fire({
            title: this.$t('landingPage.sendEmailSuccessError.waitPlease'),
            showConfirmButton: false,
            onBeforeOpen: () => {
            swal.showLoading()
            },
         })
      },

      failedSwal (title, text) {
         swal.fire({
            title: title,
            html: text,
            icon: 'error',
            confirmButtonText:'Ok',
            customClass: {
               confirmButton: 'custom-confirm-button'
            }
         })
      },

      successSwal (title, text) {
         swal.fire({
            title: title,
            html: text,
            icon: 'success',
            confirmButtonText:'Ok',
            customClass: {
               confirmButton: 'custom-confirm-button'
            }
         })
      },
    }
}
</script>
import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import { createHead } from '@vueuse/head'
import { createI18n } from 'vue-i18n'
import es from './locales/es.json';
import en from './locales/en.json';

loadFonts()

// Configura vue-i18n
const i18n = createI18n({
  locale: 'es', // Idioma predeterminado
  fallbackLocale: 'en', // Idioma de respaldo
  messages: {
    es,
    en
  }
});

const app = createApp(App)
const head = createHead()

app.use(vuetify)
app.use(router)
app.use(head)
app.use(i18n);
app.mount('#app')
